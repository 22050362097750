import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ArticleType } from './ArticleType'
import Decimal from 'decimal.js';
import { AssemblyBillOfMaterialItemStore } from './AssemblyBillOfMaterialItem';

export class AssemblyBillOfMaterial extends Model {
  static backendResourceName = 'bill_of_material_item'
  static omitFields = ['erpQuantityRemaining']

  @observable id = null
  @observable description = ''
  @observable batchQuantity = Decimal(0)
  @observable useExplosion = false


  relations() {
    return {
      articleType: ArticleType,
      items: AssemblyBillOfMaterialItemStore,
    }
  }

}

export class AssemblyBillOfMaterialStore extends Store {
  Model = AssemblyBillOfMaterial
  static backendResourceName = 'assembly_bill_of_material'
}
