import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ArticleType } from './ArticleType'
import { AssemblyBillOfMaterial } from './AssemblyBillOfMaterial';
import Decimal from 'decimal.js';

export class AssemblyBillOfMaterialItem extends Model {
  static backendResourceName = 'bill_of_material_item'
  static omitFields = ['erpQuantityRemaining']

  @observable id = null
  @observable quantity = Decimal(0)
  @observable batchQuantity = Decimal(0)


  relations() {
    return {
      articleType: ArticleType,
      assemblyBillOfMaterial: AssemblyBillOfMaterial,
    }
  }
}

export class AssemblyBillOfMaterialItemStore extends Store {
  Model = AssemblyBillOfMaterialItem
  static backendResourceName = 'assembly_bill_of_material_item'
}
